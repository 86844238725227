// page.js file in the store folder
const state = () => ({
  name: '',
  popstate: false,
})

const mutations = {
  setName (state, name) {
    state.name = name
  },
  setPopstate (state, isPopstate) {
    state.popstate = isPopstate
  },
}

const getters = {
  getName: state => state.name,
  getPopstate: state => state.popstate,
}

export default {
  state,
  mutations,
  getters
}
