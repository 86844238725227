
import vue from 'vue'
import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'
export const FETCH_DS_ITEMS = 'FETCH_DS_ITEMS'

const state = () => ({

  /** 전시 상품정보 저장 store
   * key : products_(구좌ID : exhCmptmtId)_(소재ID : exhMtrlNo)
   * value : 구좌, 소재별 상품리스트(Array)
  */
  material: {},

  // 대/중/소 카테고리 id
  dynamicId: {},
  // 대카테고리 id
  lgCtgrNo: '', // 대 카테고리
  mdCtgrNo: '', // 중 카테고리
  smCtgrNo: '', // 소 카테고리
  // 대카테고리명
  storeCtNm: '',

  /**
   * 필터링된 묶음 상품 번호 목록
   */
  filtetedBundleProducts: null,

  /**
   /* 휴일배송 여부 및 날짜
   */
  holidayDeliveryInfo: {},
})

const mutations = ({
  /**
   * 구좌별 상품 리스트 저장 업데이트
   * @param {*} state 저장소 정보(state.material)
   * @param {Array} productList 업데이트할 신규 데이터
   * @param {String} 저장소 키  => 구좌ID : exhCmptmtId
   *                              products_(구좌ID : exhCmptmtId)_(소재ID : exhMtrlNo)
   */
  setDsMaterials (state, { productObj, exhCmptmtId }) {
    const existingKey = Object.keys(state.material).find((name) => {
      return name.includes(exhCmptmtId)
    })
    if (existingKey) {
      for (const [key, value] of Object.entries(productObj)) {
        vue.set(state.material[existingKey], key, value)
      }
    } else {
      vue.set(state.material, exhCmptmtId, productObj)
    }
  },
  /**
   * 전시 구좌별 상품 정보 초기화
   * @param {*} state
   * @param {String} 저장소 키  => 구좌ID : exhCmptmtId
   */
  initDsMaterial (state, exhCmptmtId) {
    vue.set(state.material, exhCmptmtId, {})
  },
  resetState (state, exhCmptmtId) {
    delete state.material[exhCmptmtId]
  },

  // 동적 라우팅으로 받아온 대/중/소 카테고리 id 업데이트
  setDynamicId (state, id) {
    state.dynamicId = id
  },
  // 대카테고리 id 업데이트
  setLgCtgrNo (state, obj) {
    for (const [key, value] of Object.entries(obj)) {
      state[key] = value
    }
  },
  // 카테고리 페이지명 업데이트
  setCtgrPgNm (state, name) {
    state.storeCtNm = name
  },

  /**
   * 필터링된 묶음 상품 번호 목록 저장
   */
  setFiltetedBundleProducts (state, filtetedBundleProducts) {
    state.filtetedBundleProducts = filtetedBundleProducts
  },

  /**
   * 휴일배송 여부 및 휴일배송 가능일자 정보 저장
   */
  setHolidayDeliveryInfo (state, obj) {
    state.holidayDeliveryInfo = obj
  },
})

const actions = ({
  /**
   * state 저장상태 초기값 세팅
   * @param {*} key 저장소 키  => 구좌ID : exhCmptmtId
   */
  initProductKey ({ commit }, exhCmptmtId) {
    commit('initDsMaterial', exhCmptmtId)
  },
  async fetchCtgrData ({ commit, state }) {
    try {
      const lgCtgrNum = state.lgCtgrNo
      const mdCtgrNum = state.mdCtgrNo
      const smCtgrNum = state.smCtgrNo
      let lgCtgrList = []
      let mdCtgrList = []
      let smCtgrList = []
      if (lgCtgrNum !== '') {
        lgCtgrList = await this.$api.post('/ds/ctgr', { exhCtgrNos: ['CTGR_00001'] }).then((res) => { return res.data.data })
      }
      if (mdCtgrNum !== '') {
        mdCtgrList = await this.$api.post('/ds/ctgr', { exhCtgrNos: [lgCtgrNum] }).then((res) => { return res.data.data })
      }
      if (smCtgrNum !== '') {
        smCtgrList = await this.$api.post('/ds/ctgr', { exhCtgrNos: [mdCtgrNum] }).then((res) => { return res.data.data })
      }
      const largeCategory = lgCtgrList.length > 0 && lgCtgrList.find(item => item.exhCtgrNo === lgCtgrNum)
      const middleCategory = mdCtgrList.length > 0 && mdCtgrList.find(item => item.exhCtgrNo === mdCtgrNum)
      const smallCategory = smCtgrList.length > 0 && smCtgrList.find(item => item.exhCtgrNo === smCtgrNum)

      // 활성화된 카테고리 페이지명
      if (largeCategory) {
        commit('setCtgrPgNm', largeCategory.exhCtgrNm)
      } else {
        commit('setCtgrPgNm', '전체')
      }
      return {
        ...(largeCategory && { lgCtgr: state.lgCtgrNo, lgCtgrNm: largeCategory.exhCtgrNm }),
        ...(middleCategory && { mdCtgr: state.mdCtgrNo, mdCtgrNm: middleCategory.exhCtgrNm }),
        ...(smallCategory && { smCtgr: state.smCtgrNo, smCtgrNm: smallCategory.exhCtgrNm })
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error)
    }
  }
})

const getters = {
  /**
   * state 업데이트된 구좌별 데이터 조회
   * @param {*} state
   * @param {String} key 저장소 키  => 구좌ID : exhCmptmtId
   * @returns 구좌, 소재별 상품 정보 Array
   */
  getMaterialInfo: state => (exhCmptmtId) => {
    const productInfo = state.material[exhCmptmtId]
    return productInfo
  },
  /**
   * state 저장소 키 조회
   * @param {*} state
   * @param {String} exhCmptmtId 구좌별 Id
   * @returns 저장소 키  => 구좌ID : exhCmptmtId
   */
  getStoredKey: state => (exhCmptmtId) => {
    const existingKey = Object.keys(state.material).find((name) => {
      return name.includes(exhCmptmtId)
    })
    return existingKey
  },

  // 페이지에서 설정한 dynamicId Object return
  getDynamicId: state => state.dynamicId,

  // 대카고리 id return
  getLgCtgrNo: state => state.lgCtgrNo,

  // 카테고리 페이지명 return
  getCtgrPgNm: state => state.storeCtNm,

  /**
   * 필터링된 묶음 상품 번호 목록
   */
  getFiltetedBundleProducts: state => state.filtetedBundleProducts,

  /**
   * 휴일배송 여부 및 휴일배송 가능일자 정보
   */
  getHolidayDeliveryInfo: state => state.holidayDeliveryInfo,
}

export const { storeGetters, storeActions } = createNamespacedStoreExposer('ds')(getters, actions)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
