import { createNamespacedStoreExposer } from '~/plugins/common/vuex/vuex-helper'

const init = () => ({
  gnbMenuList: [],
})

const state = init

const getters = {
  getGnbMenuList: state => state.gnbMenuList,
}

const mutations = {
  init: (state) => {
  },
  setGnbMenuList: (state, payload) => (state.gnbMenuList = payload),
}

const actions = {
  init (commit) {
    commit('INIT')
  },
}

// 뷰 인스턴스에 노출하기 위한 노출 함수 제공.
export const { storeGetters, storeActions } = createNamespacedStoreExposer('local')(getters, actions)

// 스토어 내보내기.
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
